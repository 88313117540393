import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 12%;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin: 2rem 0 0;
  }

  &:first-child {
    margin: 0;
  }
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  max-width: 120px;
  margin-top: 1.5rem;
  text-transform: uppercase;
`;

const Description = styled.div`
  font-size: 1rem;
  max-width: 160px;
`;

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object
};

const defaultProps = {
  title: '',
  description: '',
  image: {}
};

function CtaColumn({ title, description, image }) {
  const imageUrl = image && image.url;
  const height = get(image, 'dimensions.height', 80);
  const width = get(image, 'dimensions.width', 80);

  return (
    <Column>
      <img src={imageUrl} height={height} width={width} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Column>
  );
}

CtaColumn.propTypes = propTypes;
CtaColumn.defaultProps = defaultProps;

export default CtaColumn;
