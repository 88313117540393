import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import CtaColumn from './CtaColumn';
import BlockStyles from '../BlockStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5.625rem auto 4.5rem;
`;

const Header = styled.h2`
  font-weight: ${prop('theme.fontWeights.semiBold')};
  font-size: 1.875rem;
  text-transform: capitalize;
  text-align: center;
`;

const Description = styled.div`
  max-width: 370px;
  text-align: center;
  margin-bottom: 3.875rem;
  font-size: 1rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
  }
`;

const LinkButton = styled.a`
  color: ${prop('theme.colors.white')};
  background-color: ${prop('theme.colors.redPrimary')};
  border-radius: 2.5rem;
  text-decoration: none;
  padding: 0.625rem 7.5rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 3.75rem;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding: 0.625rem 5rem;
  }

  &:hover {
    color: ${prop('theme.colors.white')};
    text-decoration: none;
  }
`;

const propTypes = {
  primary: PropTypes.object,
  columns: PropTypes.array,
  template: PropTypes.string
};

const defaultProps = { primary: {}, columns: [], template: '' };

function ProductsCta({ primary, columns, template }) {
  const header = primary.cta_header && primary.cta_header.text;
  const description = primary.cta_description && primary.cta_description.text;

  const buttonText = primary.button_text && primary.button_text.text;
  const buttonUrl = primary.button_link && primary.button_link.url;

  return (
    <BlockStyles template={template} data={primary}>
      <Container>
        <Header>{header}</Header>
        <Description>{description}</Description>
        <Row>
          {columns.map((column, index) => {
            const title = column.column_title && column.column_title.text;
            const description =
              column.column_description && column.column_description.text;

            return (
              <CtaColumn
                title={title}
                description={description}
                image={column.column_image}
                key={`column-${index}`}
              />
            );
          })}
        </Row>
        <LinkButton target='_blank' href={buttonUrl}>
          {buttonText}
        </LinkButton>
      </Container>
    </BlockStyles>
  );
}

ProductsCta.propTypes = propTypes;
ProductsCta.defaultProps = defaultProps;

export default ProductsCta;
